import React, { useRef, useEffect, useState, Fragment } from 'react'
import axios from 'axios'
import WebViewer from '@pdftron/webviewer'
import { useParams } from 'react-router'
import SimpleMDE from 'react-simplemde-editor'
import { Button, Modal, Select, Spin } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import 'easymde/dist/easymde.min.css'

import { isArrayEmpty, isObjectEmpty, deobfuscate } from './utils/Utils'

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL

const PrepareDoc = () => {
  const viewer = useRef(null)
  const [pdfInstance, setInstance] = useState(null)
  const [draftParticipants, setDraftParticipants] = useState([])
  const [selectedParticipant, setSelectedParticipant] = useState(null)
  const [prepared, setPrepared] = useState(false)
  const [isReady, setIsReady] = useState(false)
  const [modalFinish, setModalFinish] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [message, setMessage] = useState('')
  const [alertError, setAlertError] = useState('')

  const { id } = useParams()

  const getAnnotationsByPage = (annotationsList, pageNumber) =>
    annotationsList.filter((annot) => annot.PageNumber === pageNumber)

  const getAnnotationStyle = (widget, annotation) => {
    let annotStyle = {}
    if (widget instanceof annotation.SignatureWidgetAnnotation) {
      annotStyle = {
        border: '1px solid #a5c7ff',
      }
    }
    return annotStyle
  }

  const getWindowCoordinatesCenter = (scrollView) => {
    const top = scrollView.scrollTop + scrollView.offsetTop
    const bottom = top + scrollView.offsetHeight
    const left = scrollView.scrollLeft + scrollView.offsetLeft
    const right = left + scrollView.offsetWidth

    return {
      x: (left + right) / 2,
      y: (top + bottom) / 2,
    }
  }

  const getSignerEmails = (annotationsList) => {
    return annotationsList.reduce((signers, annot) => {
      let custom = annot.getCustomData('custom')
      if (custom) {
        custom = JSON.parse(custom)
        if (custom.type === 'SIGNATURE') {
          return [...new Set([...signers, custom.email])]
        }
      }
      return signers
    }, [])
  }

  // if using a class, equivalent of componentDidMount
  useEffect(() => {
    Promise.all([
      axios.get(`/signTask/${id}`),
      WebViewer(
        {
          licenseKey: deobfuscate(process.env.REACT_APP_PDFTRON_KEY),
          fullAPI: true,
          path: '../webviewer/lib',
          disabledElements: [
            'toolsButton',
            'searchButton',
            'menuButton',
            'contextMenuPopup',
            'freeHandToolGroupButton',
            'textToolGroupButton',
            'shapeToolGroupButton',
            'signatureToolButton',
            'eraserToolButton',
            'stickyToolButton',
            'freeTextToolButton',
            'miscToolGroupButton',
          ],
        },
        viewer.current,
      ),
    ])
      .then(async ([axiosResponse, instance]) => {
        const { draftLink, participants, xfdf } = axiosResponse.data
        setDraftParticipants(participants)
        setSelectedParticipant(participants[0])
        const { documentViewer, annotationManager, Annotations } = instance.Core
        const { LayoutMode } = instance.UI

        setInstance(instance)
        // load document
        const URL = draftLink
        await documentViewer.loadDocument(URL, { loadAsPDF: true })
        instance.UI.setLayoutMode(LayoutMode.Single)
        if (xfdf) {
          setIsReady(true)
          setPrepared(true)
          const normalStyles = (widget) => getAnnotationStyle(widget, Annotations)
          annotationManager.addEventListener(
            'annotationChanged',
            async (annotations, action, { imported }) => {
              if (imported && action === 'add') {
                annotations.forEach((annot) => {
                  if (annot instanceof Annotations.WidgetAnnotation) {
                    Annotations.WidgetAnnotation.getCustomStyles = normalStyles
                  }
                })
              }
            },
          )

          annotationManager.importAnnotations(xfdf)
          annotationManager.enableReadOnlyMode()
        } else {
          const OFFSET = 24

          annotationManager.addEventListener(
            'annotationChanged',
            async (annotations, action, { imported }) => {
              const currentPage = documentViewer.getCurrentPage()
              const annotationsList = annotationManager.getAnnotationsList()
              const annotationsByPage = getAnnotationsByPage(annotationsList, currentPage)

              // Only offsets if the annotation is a signature field
              if (!isArrayEmpty(annotationsByPage)) {
                const currentIdx = annotationsByPage.length - 1
                const current = annotationsByPage[currentIdx]
                if (currentIdx > 0) {
                  const previous = annotationsByPage[currentIdx - 1]
                  const custom = previous.getCustomData('custom')
                  const isSignField = !!(
                    custom && JSON.parse(custom).type === 'SIGNATURE'
                  )
                  if (imported && action === 'add' && isSignField) {
                    current.Y = previous.Y + OFFSET
                  }
                }
              }

              const allSignerEmails = getSignerEmails(annotationsList)
              const missingSigners = participants
                .filter((participant) => !allSignerEmails.includes(participant.email))
                .map((p) => p.signerName)
              if (missingSigners && missingSigners.length === 0) {
                setIsReady(true)
              } else {
                setIsReady(false)
              }
            },
          )
        }
      })
      .catch((error) => {
        if (error.response) {
          const { data } = error.response
          if (data && data.message.includes('id: Invalid')) {
            setAlertError(
              // eslint-disable-next-line max-len
              'The link provided is invalid. Please check with support to verify if the link exists.',
            )
          } else {
            setAlertError(data.message)
          }
        }
      })
      .finally(() => setIsLoading(false))
  }, [])

  const generateUUID = () => {
    // Timestamp
    let d = new Date().getTime()
    // Time in microseconds since page-load or 0 if unsupported
    let d2 = (performance && performance.now && performance.now() * 1000) || 0
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // random number between 0 and 16
      let r = Math.random() * 16
      // Use timestamp until depleted
      if (d > 0) {
        r = (d + r) % 16 | 0
        d = Math.floor(d / 16)
        // Use microseconds since page-load if supported
      } else {
        r = (d2 + r) % 16 | 0
        d2 = Math.floor(d2 / 16)
      }
      // eslint-disable-next-line no-mixed-operators
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
    })
  }

  const addField = (type, point = {}, flag = {}) => {
    const { documentViewer, Annotations } = pdfInstance.Core
    const annotManager = documentViewer.getAnnotationManager()
    const displayMode = documentViewer.getDisplayModeManager().getDisplayMode()
    const scrollView = documentViewer.getScrollViewElement()

    const windowCoordinatesCenter = getWindowCoordinatesCenter(scrollView)
    const page = displayMode.getSelectedPages(
      windowCoordinatesCenter,
      windowCoordinatesCenter,
    )
    if (!!point.x && page.first == null) {
      return // don't add field to an invalid page location
    }
    const pageIdx = page.first !== null ? page.first : documentViewer.getCurrentPage()
    const zoom = documentViewer.getZoomLevel()

    const textAnnot = new Annotations.FreeTextAnnotation()
    textAnnot.PageNumber = pageIdx
    const rotation = documentViewer.getCompleteRotation(pageIdx) * 90
    textAnnot.Rotation = rotation
    if (rotation === 270 || rotation === 90) {
      textAnnot.Width = 30.0 / zoom
      textAnnot.Height = 120.0 / zoom
    } else {
      textAnnot.Width = 120.0 / zoom
      textAnnot.Height = 30.0 / zoom
    }

    const pageCoordinates = displayMode.windowToPage(windowCoordinatesCenter, pageIdx)
    const centerX = pageCoordinates.x - textAnnot.Width / 2
    const centerY = pageCoordinates.y - textAnnot.Height / 2
    textAnnot.X = centerX
    textAnnot.Y = centerY

    textAnnot.setPadding(new Annotations.Rect(0, 0, 0, 0))
    const { email, _id, signerName } = selectedParticipant
    const custom = {
      type,
      email,
      _id,
      flag,
      name: `${signerName}_${type}_`,
    }
    textAnnot.setCustomData('custom', JSON.stringify(custom))

    // set the type of annot
    textAnnot.setContents(custom.name)
    textAnnot.FontSize = `${10.0 / zoom}px`
    textAnnot.FillColor = new Annotations.Color(211, 211, 211, 0.5)
    textAnnot.TextColor = new Annotations.Color(0, 165, 228)
    textAnnot.StrokeThickness = 1
    textAnnot.StrokeColor = new Annotations.Color(0, 165, 228)
    textAnnot.TextAlign = 'center'

    textAnnot.Author = annotManager.getCurrentUser()

    annotManager.deselectAllAnnotations()
    annotManager.addAnnotation(textAnnot, true)
    annotManager.redrawAnnotation(textAnnot)
    annotManager.selectAnnotation(textAnnot)
  }

  const uploadForSigning = async (participants) => {
    // upload the PDF with fields as AcroForm

    const { annotationManager } = pdfInstance.Core
    const xfdf = await annotationManager.exportAnnotations()

    axios
      .put(`/signTask/${id}`, { xfdf, participants, message })
      .then(() => {
        window.opener.postMessage({ type: 'prepared', id }, '*')
        window.close()
        Modal.success({
          content: 'Success! Please close this window!',
        })
      })
      .catch((error) => {
        console.error(error)
        if (error.response) {
          const data = error.response.data || {}
          setAlertError(`Error Message: ${data.message || ''}`)
        }
      })
  }

  const applyFields = async () => {
    const { documentViewer, Annotations } = pdfInstance.Core
    const annotManager = documentViewer.getAnnotationManager()
    const fieldManager = annotManager.getFieldManager()
    const annotationsList = annotManager.getAnnotationsList()
    const annotsToDelete = []
    const annotsToDraw = []
    const signerTokenMap = draftParticipants.reduce(
      (map, participant) => ({
        ...map,
        [participant._id]: generateUUID(),
      }),
      {},
    )
    const participantMap = {}
    await Promise.all(
      annotationsList.map(async (annot, index) => {
        let custom = annot.getCustomData('custom')
        let inputAnnot
        let field

        if (custom) {
          // set flags
          custom = JSON.parse(custom)
          const flags = new Annotations.WidgetFlags()

          if (!isObjectEmpty(custom.flag) && custom.flag.readOnly) {
            flags.set('ReadOnly', true)
          }
          if (!isObjectEmpty(custom.flag) && custom.flag.multiline) {
            flags.set('Multiline', true)
          }

          // create a form field based on the type of annotation
          if (custom.type === 'TEXT') {
            field = new Annotations.Forms.Field(
              annot.getContents() + Date.now() + index,
              {
                type: 'Tx',
                value: custom.value,
                flags,
              },
            )
            inputAnnot = new Annotations.TextWidgetAnnotation(field)
          } else if (custom.type === 'SIGNATURE') {
            // const signToken = annot.getContents() + Date.now() + index;
            const { email, _id } = custom
            participantMap[_id] = {
              email,
              signToken: signerTokenMap[_id],
              signed: false,
              _id,
              requestedTime: new Date(),
            }
            field = new Annotations.Forms.Field(signerTokenMap[_id], {
              type: 'Sig',
              flags,
            })
            inputAnnot = new Annotations.SignatureWidgetAnnotation(field, {
              appearance: '_DEFAULT',
              appearances: {
                _DEFAULT: {
                  Normal: {
                    data:
                      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXN'
                      + 'SR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNv'
                      + 'ZnR3YXJlAHBhaW50Lm5ldCA0LjEuMWMqnEsAAAANSURBVBhXY/j//z8DAAj8Av6IXwbgAAAAA'
                      + 'ElFTkSuQmCC',
                    offset: {
                      x: 100,
                      y: 100,
                    },
                  },
                },
              },
            })
          } else {
            // exit early for other annotations
            annotsToDelete.push(annot)
            return
          }
        } else {
          // exit early for other annotations
          if (
            annot instanceof Annotations.FreeTextAnnotation
            && annot.Author !== 'Guest'
          ) {
            annotsToDelete.push(annot)
          }
          return
        }

        // set flag and position
        inputAnnot.PageNumber = annot.getPageNumber()
        inputAnnot.X = annot.getX()
        inputAnnot.Y = annot.getY()
        inputAnnot.rotation = annot.Rotation
        if (annot.Rotation === 0 || annot.Rotation === 180) {
          inputAnnot.Width = annot.getWidth()
          inputAnnot.Height = annot.getHeight()
        } else {
          inputAnnot.Width = annot.getHeight()
          inputAnnot.Height = annot.getWidth()
        }

        // delete original annotation
        annotsToDelete.push(annot)

        // customize styles of the form field
        Annotations.WidgetAnnotation.getCustomStyles = (widget) =>
          getAnnotationStyle(widget, Annotations)
        Annotations.WidgetAnnotation.getCustomStyles(inputAnnot)

        // draw the annotation the viewer
        annotManager.addAnnotation(inputAnnot)
        fieldManager.addField(field)
        annotsToDraw.push(inputAnnot)
      }),
    )

    // delete old annotations
    annotManager.deleteAnnotations(annotsToDelete, null, true)

    // refresh viewer
    annotManager.drawAnnotationsFromList(annotsToDraw)
    await uploadForSigning(Object.values(participantMap))
  }

  const checkSigner = () => {
    const { documentViewer } = pdfInstance.Core
    const annotManager = documentViewer.getAnnotationManager()
    const annotationsList = annotManager.getAnnotationsList()
    const allSignerEmails = getSignerEmails(annotationsList)
    const missingParticipants = draftParticipants
      .filter((participant) => !allSignerEmails.includes(participant.email))
      .map((p) => p.signerName)
    return missingParticipants
  }

  const checkAndApplyFields = () => {
    const missingParticipants = checkSigner()
    if (missingParticipants.length > 0) {
      Modal.warning({
        content: `You have not yet placed the signing field for ${missingParticipants.join(
          ', ',
        )} - please do that first.`,
      })
    } else {
      applyFields()
    }
  }

  const getModalFinish = () => {
    if (!modalFinish) return null
    return (
      <Modal
        title="Almost there..."
        visible={modalFinish}
        okText="Finish"
        onOk={() => {
          checkAndApplyFields()
          setModalFinish(false)
        }}
        onCancel={() => {
          setModalFinish(false)
        }}
      >
        <p className="modal-paragraph">
          To send this document, press the Send documents for signing button in your
          Console. A sign request will be sent via e-mail to all signers. A duplicate will
          be sent to all people you’ve copied.
        </p>
        <label className="text-small" htmlFor="message">
          Message (Optional)
        </label>
        <SimpleMDE
          id="message"
          placeholder="Add a message for all signers"
          value={message}
          onChange={(value) => {
            setMessage(value)
          }}
        />
      </Modal>
    )
  }

  const getAlertMessage = () => {
    let alert
    if (alertError) {
      alert = alertError
    } else if (prepared) {
      alert = 'This document has already been prepared or sent for signing and cannot be amended.'
    } else if (isReady) {
      alert = 'Press Finish to Continue.'
    } else if (!selectedParticipant) {
      alert = 'Please select a signer.'
    } else {
      alert = 'Place a signature box for every signer.'
    }
    return alert
  }

  const uniqueList = [
    ...new Map(draftParticipants.map((item) => [item.email, item])).values(),
  ]

  return (
    <Spin spinning={isLoading} delay={100}>
      <div className="App">
        <div className="header">
          <img className="logo" alt="logo" src="/assets/eSign_Logo_negative.png" />
          {draftParticipants.length && draftParticipants.length > 0 && (
            <Fragment>
              <Select
                disabled={prepared}
                defaultValue={draftParticipants[0].email}
                className="select-signer"
                onChange={(email) =>
                  setSelectedParticipant(draftParticipants.find((p) => p.email === email))
                }
              >
                {uniqueList.map((p) => (
                  <Select.Option key={p.email} value={p.email}>
                    {`${p.signerName} (${p.email})`}
                  </Select.Option>
                ))}
              </Select>
              <Button disabled={prepared} ghost onClick={() => addField('SIGNATURE')}>
                <PlusOutlined />
                Add Sign Field
              </Button>
            </Fragment>
          )}
          {prepared ? (
            <Button className="send-button" type="primary" onClick={() => window.close()}>
              Close
            </Button>
          ) : (
            <Button
              className={`send-button ${isReady ? '' : 'disable'}`}
              type="primary"
              onClick={() => { setModalFinish(isReady) }}
            >
              Finish
            </Button>
          )}
        </div>
        <div className={`alert-bar ${isReady ? 'complete' : ''}`}>
          {getAlertMessage()}
        </div>
        {getModalFinish()}
        <div className="webviewer" ref={viewer} />
      </div>
    </Spin>
  )
}

export default PrepareDoc
