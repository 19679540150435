import React, { Fragment, useRef, useEffect, useState } from 'react'
import { useParams } from 'react-router'

import axios from 'axios'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import WebViewer from '@pdftron/webviewer'

import { Button, Modal, Switch, Spin } from 'antd'

import ErrorCodes from './enums/ErrorCodes'
import ErrorMessages from './enums/ErrorMessages'
import DateTypes from './enums/DateTypes'
import WebViewerCreateToolKeys from './enums/WebViewerCreateToolKeys'
import WebViewerEvents from './enums/WebViewerEvents'
import {
  isArrayEmpty,
  logError,
  parseJSON,
  deobfuscate,
  getAnnotationsToSave,
} from './utils/Utils'

import Confirmation from './pages/Confirmation'

dayjs.extend(utc)
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL

const SignDoc = () => {
  const viewer = useRef(null)
  const [pdfInstance, setInstance] = useState(null)
  const [alertError, setAlertError] = useState('')
  const [header, setHeader] = useState('')
  const [presign, setPreSignSigUrl] = useState('')
  const [sigBuf, setSigBuf] = useState('')
  const [signUrl, setSignUrl] = useState('')
  const [isSigned, setIsSigned] = useState(false)
  const [hasSig, setHasSig] = useState(false)
  const [isReady, setIsReady] = useState(false)
  const [isReadAcknowledge, setIsReadAcknowledge] = useState(false)
  const [saveSig, enableSave] = useState(false)
  const [isLowRes, setIsLowRes] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [lastUpdateTime, setLastUpdateTime] = useState(new Date())
  const [signTaskData, setSignTaskData] = useState({})
  const { token } = useParams()

  const checkSignature = (signerFields) => {
    const allSigned = signerFields.every((signField) => {
      const annot = signField.getAssociatedSignatureAnnotation()
      if (annot && annot.ToolName !== 'AnnotationCreateFreeHand') {
        const { Height, Width, image } = annot
        setIsLowRes((Width + Height) * 0.9 > image.naturalWidth + image.naturalHeight)
      }
      return !!annot && !isLowRes
    })
    setIsReady(allSigned)
  }

  // Convert the Canvas to a Blob Object for Upload
  const convertToBlob = async (canvas, preSignLink) => {
    try {
      canvas.toBlob(
        async (blob) => {
          const buf = await blob.arrayBuffer()
          setPreSignSigUrl(preSignLink)
          setSigBuf(buf)
        },
        'image/png',
        1,
      )
      return true
    } catch (error) {
      throw new Error(error)
    }
  }

  const extractSignature = async (annotation, docViewer, preSignLink) => {
    // Only text, and upload uses image; freehand doesn't
    const { image } = annotation
    const isFreeHand = annotation.ToolName === 'AnnotationCreateFreeHand'

    const canvas = document.createElement('canvas')
    const imgCanvas = document.createElement('canvas')
    const imgCtx = imgCanvas.getContext('2d')

    // Reference the annotation from the Document
    const pageMatrix = docViewer
      .getDocument()
      .getPageMatrix(annotation.PageNumber)

    // Set the height & width of the canvas to match the annotation
    canvas.height = annotation.Height
    canvas.width = annotation.Width
    const ctx = canvas.getContext('2d')

    // Translate the Annotation to the top Top Left Corner of the Canvas ie (0, 0)
    ctx.translate(-annotation.X, -annotation.Y)

    // Draw the Annotation onto the Canvas
    annotation.draw(ctx, pageMatrix)

    // Upscaling the image/annotation (and canvas) to be drawn in the canvas used for uploading
    if (image && !isFreeHand) {
      image.onload = async () => {
        imgCanvas.width = image.naturalWidth
        imgCanvas.height = image.naturalHeight
        imgCtx.drawImage(image, 0, 0)
        await convertToBlob(imgCanvas, preSignLink)
      }
    } else {
      imgCanvas.width = Math.ceil(annotation.Width * 1.5)
      imgCanvas.height = Math.ceil(annotation.Height * 1.5)
      imgCtx.scale(1.5, 1.5)
      imgCtx.translate(-annotation.X, -annotation.Y)
      annotation.draw(imgCtx, pageMatrix)
      await convertToBlob(imgCanvas, preSignLink)
    }
  }

  const sendEmailForExpiredToken = () => axios.post(`/signTask/newToken/${token}`)

  useEffect(() => {
    Promise.all([
      axios.get(`/signTask/token/${token}`),
      WebViewer(
        {
          licenseKey: deobfuscate(process.env.REACT_APP_PDFTRON_KEY),
          fullAPI: true,
          path: '../webviewer/lib',
          disabledElements: [
            'toolsButton',
            'searchButton',
            'menuButton',
            'contextMenuPopup',
            'freeHandToolGroupButton',
            'textToolGroupButton',
            'shapeToolGroupButton',
            'signatureToolButton',
            'eraserToolButton',
            'stickyToolButton',
            'freeTextToolButton',
            'miscToolGroupButton',
            'fileAttachmentToolButton',
            'rubberStampToolButton',
            'stampToolButton',
            'calloutToolButton',
            'leftPanel',
            'searchPanel',
            'viewControlsOverlay',
            'menuOverlay',
            'annotationCommentButton',
            'annotationStyleEditButton',
            'annotationStylePopup',
            'stylePopup',
            'textPopup',
            'contextMenuPopup',
            'calloutToolGroupButton',
            'toolbarGroup-Annotate',
            'toolbarGroup-Forms',
            'toolbarGroup-Insert',
            'toolbarGroup-Shapes',
          ],
        },
        viewer.current,
      ),
    ])
      .then(async ([axiosResponse, instance]) => {
        const { documentViewer, annotationManager, Annotations, Tools } = instance.Core
        const { Feature } = instance.UI
        instance.disableElements(['signatureToolGroupButton'])
        instance.UI.disableFeatures([Feature.Copy])
        instance.UI.setHeaderItems((docHeader) => {
          const zoomOverlayButton = docHeader.getItems().find((item) => (
            item.dataElement === 'zoomOverlayButton'
          ))
          docHeader.update([{ ...zoomOverlayButton, hiddenOnMobileDevice: false }])
        })

        const signatureTool = documentViewer.getTool(Tools.ToolNames.SIGNATURE)
        signatureTool.addEventListener(WebViewerEvents.ANNOTATION_ADDED, () => {
          // so when you return to the insert group the signature won't be visible
          instance.setToolMode(Tools.ToolNames.EDIT)
          instance.UI.closeElements(['toolStylePopup'])
        })

        setInstance(instance)

        // load document
        const {
          containsXfdf = false,
          pdfLink,
          xfdf,
          title,
          signedCount,
          participantCount,
          lastUpdateTime: resultLastUpdateTime,
          base64Signature,
          preSignPutUrl,
          recipientId,
          recipientIds = [],
          signUrl: newSignUrl,
        } = axiosResponse.data

        if (newSignUrl) {
          setShowConfirm(true)
          setSignUrl(newSignUrl)
          return
        }

        setSignTaskData(axiosResponse.data || {})
        setLastUpdateTime(resultLastUpdateTime)
        setHeader(`${title} (${signedCount} of ${participantCount} completed)`)
        await documentViewer.loadDocument(pdfLink)

        if (base64Signature) {
          signatureTool.importSignatures([`data:image/png;base64,${base64Signature}`])
          setHasSig(true)
        }

        const pageIdx = documentViewer.getCurrentPage()
        const rotation = documentViewer.getCompleteRotation(pageIdx) * 90
        const isPortrait = rotation === 0 || rotation === 180

        // QuickSign 1
        // Initialize the signature index
        let signatureIndex = -1
        signatureTool.addEventListener(WebViewerEvents.SIGNATURE_SAVED,
          () => { signatureIndex += 1 })

        // QuickSign 2
        // Get the signature from the signature panel
        const wvIframe = document.querySelector('#webviewer-1')
        wvIframe.contentWindow.addEventListener('visibilityChanged', (e) => {
          const { isVisible, element } = e.detail
          if (isVisible && element === 'toolStylePopup') {
            // Set a timeout to wait for UI to update
            setTimeout(() => {
              const signaturePanel = wvIframe.contentDocument
                .querySelector('[data-element="toolStylePopup"]')
              if (signaturePanel) {
                signaturePanel.querySelectorAll('.signature-row').forEach((row, index) => {
                  row.querySelector('.signature-row-content').addEventListener('click', () => {
                    signatureIndex = index
                  })
                })
              }
            }, 50)
          }
        })

        // QuickSign 3
        // Add the saved signature to the sign field
        signatureTool.addEventListener(WebViewerEvents.LOCATION_SELECTED, () => {
          const signature = signatureTool.getSavedSignatures()[signatureIndex]
          signatureTool.setSignature(signature)
          signatureTool.addSignature()
          instance.UI.closeElements(['signatureModal', 'toolStylePopup'])
        })

        // QuickSign 4
        const defaultMouseLeftUp = signatureTool.mouseLeftUp
        signatureTool.mouseLeftUp = function disableOnClickPreview(e, widget) {
          // ignore "mouseLeftUp" event when no widget is selected
          if (!widget) return
          // eslint-disable-next-line prefer-rest-params
          defaultMouseLeftUp.apply(this, arguments)
        }
        // disable the default "switchIn" or "showPreview" event
        signatureTool.switchIn = () => {}

        const normalStyles = (widget) => {
          let annotStyle = {}
          if (widget instanceof Annotations.SignatureWidgetAnnotation) {
            let borderColor = [0, 0, 0]
            const annotCustom = widget.getCustomData('custom')
            if (annotCustom) {
              const parsedCustom = JSON.parse(annotCustom)
              const { colors } = parsedCustom
              borderColor = colors.strokeColor
            }
            annotStyle = {
              border: `1px solid rgba(${borderColor})`,
            }
          }
          return annotStyle
        }

        annotationManager.addEventListener(WebViewerEvents.ANNOTATION_SELECTED,
          async (annotations) => {
            /* To fix issue with users deleting other signatures */
            if (annotations && annotations.length) {
              for (const annot of annotations) {
                annot.NoDelete = !annot.IsAdded
              }
            }
          },
        )

        const readerFields = []
        const signerFields = []
        annotationManager.addEventListener(WebViewerEvents.ANNOTATION_CHANGED,
          async (annotations, action, { imported }) => {
            for (const annot of annotations) {
              annot.NoMove = true
              annot.NoResize = true
              annot.Listable = false
              annot.disableRotationControl()
              const custom = parseJSON(annot.getCustomData('custom'))
              const fieldId = recipientId || token
              if (custom) {
                const { type } = custom
                if (type === 'READ_AND_ACKNOWLEDGE') {
                  readerFields.push(annot)
                  if (!isReadAcknowledge) setIsReadAcknowledge(true)
                }
              }
              if (annot instanceof Annotations.SignatureWidgetAnnotation) {
                if (imported && action === 'add') {
                  // init style
                  Annotations.WidgetAnnotation.getCustomStyles = normalStyles
                  annot.fieldFlags.set('ReadOnly', false)
                  if (annot.fieldName !== fieldId && !recipientIds.includes(annot.fieldName)) {
                    annot.Hidden = true
                  } else {
                    if (!isPortrait) {
                      const temp = annot.Width
                      annot.rotation = rotation
                      annot.Width = annot.Height
                      annot.Height = temp
                    }
                    signerFields.push(annot)
                  }
                }
              }
              if (annot.Subject === 'Signature') {
                annot.Listable = true
                if (action === 'add' && !imported && preSignPutUrl) {
                  // eslint-disable-next-line no-await-in-loop
                  await extractSignature(annot, documentViewer, preSignPutUrl)
                }
              }
            }
            checkSignature(signerFields)
          },
        )

        annotationManager.addEventListener(WebViewerEvents.ANNOTATION_DESELECTED, () => {
          checkSignature(signerFields)
        })


        annotationManager.addEventListener(WebViewerEvents.ANNOTATIONS_DRAWN, () => {
          const annotationsList = annotationManager.getAnnotationsList()

          const fieldId = recipientId || token

          // Indicates whether the active document is signed
          setIsSigned(containsXfdf)

          if (containsXfdf) {
            const annotsToHide = annotationsList.filter((annotation) => (
              annotation.fieldName !== fieldId
              && !recipientIds.includes(annotation.fieldName)
              && !(annotation.ToolName === 'AnnotationCreateFreeText' && annotation.isInternal())
            ))
            if (!isArrayEmpty(annotsToHide)) {
              annotationManager.hideAnnotations(annotsToHide)
            }
          }
        }, { once: true })

        documentViewer.addEventListener(WebViewerEvents.ANNOTATIONS_LOADED, () => {
          const annotationsList = annotationManager.getAnnotationsList()

          // Delete annotations that are not Printable
          // Printable = annotations that can be seen when document is being printed
          const annotationsToDelete = annotationsList.filter((annotation) => !annotation.Printable)

          if (!isArrayEmpty(annotationsToDelete)) {
            annotationManager.deleteAnnotations(annotationsToDelete)
          }
        })

        annotationManager.importAnnotations(xfdf)
        if (isArrayEmpty(signerFields) && isArrayEmpty(readerFields)) {
          setIsReady(false)
          setAlertError(
            'Something went wrong, your signature box is missing. Please contact our support.',
          )
        }
      })
      .catch(async (error) => {
        if (error.response) {
          const { data } = error.response
          if (data && data.code.includes(ErrorCodes.BMSIGN_SIGNER_COMPLETED)) {
            const {
              participantCount,
              signedCount,
              signUrl: nextSignUrl,
              title,
            } = data
            setShowConfirm(true)
            if (nextSignUrl) setSignUrl(nextSignUrl)
            if (title && signedCount && participantCount) {
              setHeader(
                `${title} (${signedCount} of ${participantCount} completed)`,
              )
            }
          } else if (data.code === ErrorCodes.TOKEN_EXPIRED) {
            setAlertError(
              'For your security this link has expired - please see your email for the fresh link',
            )
            await sendEmailForExpiredToken()
          } else if (data.code === ErrorCodes.ENVELOPE_EXPIRED) {
            setAlertError(
              'This Envelope has expired and can no longer be signed',
            )
          } else {
            setAlertError(data.message)
          }
        }
        logError(error)
      })
      .finally(() => setIsLoading(false))
  }, [])

  const confirm = async () => {
    const { annotationManager, Annotations, Tools } = pdfInstance.Core
    const annotationsList = annotationManager.getAnnotationsList()
    const annotateKeys = Object.values(WebViewerCreateToolKeys)
    const annotationCreateToolNames = []
    for (const [key, value] of Object.entries(Tools.ToolNames)) {
      if (annotateKeys.includes(key)) annotationCreateToolNames.push(value)
    }

    const {
      recipientIds = [],
      signedCount: sCount,
      participantCount: pCount,
      dateFormat = 'M/D/YYYY',
      utfOffset = 8,
    } = signTaskData

    const replaceDateAnnotation = (annot) => {
      const date = dayjs().utc().utcOffset(utfOffset).format(dateFormat)
      annot.setContents(`${date}`)
      // eslint-disable-next-line no-param-reassign
      annot.FontSize = '12px'
      // eslint-disable-next-line no-param-reassign
      annot.FillColor = new Annotations.Color(255, 255, 255)
      // eslint-disable-next-line no-param-reassign
      annot.TextColor = new Annotations.Color(85, 85, 85)
      // eslint-disable-next-line no-param-reassign
      annot.StrokeThickness = 0
      // eslint-disable-next-line no-param-reassign
      annot.StrokeColor = new Annotations.Color(0, 0, 0)
      // eslint-disable-next-line no-param-reassign
      annot.TextAlign = 'left'
    }

    for (const annot of annotationsList) {
      const custom = parseJSON(annot.getCustomData('custom'))

      if (
        custom.type === DateTypes.DATE_DOCUMENT_COMPLETED
        && sCount === pCount - 1
      ) {
        replaceDateAnnotation(annot)
      }

      // eslint-disable-next-line no-continue
      if (!recipientIds.includes(custom.signerId)) continue

      if (custom.type === DateTypes.DATE_SIGNED) {
        replaceDateAnnotation(annot)
      }
    }

    const annotationsToSave = getAnnotationsToSave(annotationsList,
      isSigned, annotationCreateToolNames)
    const xfdf = await annotationManager.exportAnnotations({
      annotList: annotationsToSave,
      widgets: true,
    })
    try {
      const response = await axios.post(`/signTask/sign/${token}`, {
        xfdf,
        lastUpdateTime,
        isReadAcknowledge,
      })
      if (saveSig && sigBuf && presign) {
        await axios.put(presign, sigBuf, {
          headers: {
            'Content-Type': 'image/png',
            'Content-Encoding': 'base64',
          },
        })
        await axios.post(`/signTask/signature/${token}`, {
          s3Location: `${token}.png`,
        })
      }
      if (response.data) {
        const {
          participantCount,
          signedCount,
          signUrl: nextSignUrl,
          title,
        } = response.data
        if (nextSignUrl) setSignUrl(nextSignUrl)
        if (title && signedCount && participantCount) {
          setHeader(`${title} (${signedCount} of ${participantCount} completed)`)
        }
      }
      setShowConfirm(true)
    } catch (error) {
      if (error.response.data) {
        const { message, code } = error.response.data
        if (code === ErrorCodes.BMSIGN_MODIFIED_AGREEMENT) {
          Modal.confirm({
            title: ErrorMessages.TITLE_AGREEMENT_MODIFIED,
            content: ErrorMessages.MSG_AGREEMENT_MODIFIED,
            onOk() {
              window.location.reload()
            },
          })
        } else {
          Modal.error({
            title: ErrorMessages.TITLE_GENERIC,
            content: message === ErrorMessages.MSG_REQUEST_ENTITY_TOO_LARGE
              ? ErrorMessages.MSG_SIZE_TOO_LARGE
              : message,
          })
        }
      }
    }
  }

  const getAlertMessage = () => {
    let alert
    if (alertError) {
      alert = alertError
    } else if (isReady) {
      alert = isLowRes
        ? 'Your saved signature is too small for this signing field, '
          + 'causing the signature to look low-quality. Please use or create a new signature.'
        : `Press Finish to submit ${
          saveSig ? 'and save' : ''
        } your digital signature. We will let the sender know you have signed.`
      if (isReadAcknowledge) {
        alert = `Press Acknowledge to confirm that you have read and acknowledged this document. 
        We will let sender know you have read this document.`
      }
    } else if (signTaskData.signerStatus === 'PENDING') {
      alert = 'Your signature is requested, please review this document'
        + ' and click on the signature box to sign.'
    }

    return (
      <div className={`alert-bar ${isReady ? 'complete' : ''}`}>
        <Fragment>
          {alert}
          {(isReady && !isReadAcknowledge) && (
            <div className="signature-toggle">
              <span>{hasSig ? 'Update and' : ''} Save Signature</span>
              <Switch
                checked={saveSig}
                onChange={() => enableSave(!saveSig)}
                size="small"
              />
            </div>
          )}
        </Fragment>
      </div>
    )
  }

  return (
    <Spin spinning={isLoading} delay={100}>
      <div className="App">
        <div className="header">
          <img
            className="logo"
            alt="logo"
            src="/assets/eSign_Logo_negative.png"
          />
          <span className="doc-title">{header}</span>
          {!showConfirm && (
            <Button
              className="send-button"
              type="primary"
              disabled={!isReady}
              onClick={() => confirm()}
            >
              {isReadAcknowledge ? 'Acknowledge' : 'Finish'}
            </Button>
          )}
        </div>
        {showConfirm ? (
          <Confirmation signUrl={signUrl} />
        ) : (
          <>
            {getAlertMessage()}
            <div className="webviewer" ref={viewer} />
          </>
        )}
      </div>
    </Spin>
  )
}

export default SignDoc
