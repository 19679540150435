import React from 'react'
import ReactDOM from 'react-dom'
import { datadogRum } from '@datadog/browser-rum'
import 'antd/dist/antd.css'
import './index.css'
import App from './App'

let datadogParams = {}

switch (process.env.REACT_APP_ENV) {
  case 'dashboard':
    datadogParams = {
      applicationId: 'b517b712-46e9-43b5-969e-6b93f7656079',
      clientToken: 'pub4b71eccb860d46c1187d3ae992c281bd',
      env: 'bmprod',
    }
    break
  case 'sandbox':
    datadogParams = {
      applicationId: '97a532b9-e54f-4671-93a8-3e4c3db0a83e',
      clientToken: 'pub9e91ec1c98d26fc8f6f14c28c74577ca',
      env: 'sandbox',
    }
    break
  case 'tricor':
    datadogParams = {
      applicationId: '195cf3d5-e199-4cba-9ea7-1ce62aa3bdb2',
      clientToken: 'pub3a36e92107ce5724ca95881c485bab73',
      env: 'tricor-prod',
    }
    break
  case 'dev':
    datadogParams = {
      applicationId: '92f9e289-3558-4694-9c7a-d359294c9b03',
      clientToken: 'pub81ae5fee070dc58cc5865b5d0e761af6',
      env: 'bmdev',
    }
    break
  default:
    datadogParams = {}
    break
}

if (Object.entries(datadogParams).length > 0 && !window.location.pathname.includes('localhost')) {
  const params = {
    ...datadogParams,
    service: 'bluemeg-sign',
    site: 'datadoghq.eu',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackFrustrations: true,
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  }

  datadogRum.init(params)
  datadogRum.startSessionReplayRecording()
}

ReactDOM.render(<App />, document.getElementById('root'))
