import React, { useRef, useEffect, useState } from 'react'
import axios from 'axios'
import WebViewer from '@pdftron/webviewer'
import { useParams } from 'react-router'
import { Spin } from 'antd'

import { deobfuscate } from './utils/Utils'

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL

const CopyDoc = () => {
  const viewer = useRef(null)
  const [header, setHeader] = useState('')
  const [alertError, setAlertError] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const { token } = useParams()

  useEffect(() => {
    Promise.all([
      axios.get(`/signTask/copy/token/${token}`),
      WebViewer(
        {
          licenseKey: deobfuscate(process.env.REACT_APP_PDFTRON_KEY),
          fullAPI: true,
          path: '../webviewer/lib',
          disabledElements: [
            'toolsHeader',
            'toolsButton',
            'searchButton',
            'menuButton',
            'contextMenuPopup',
            'freeHandToolGroupButton',
            'textToolGroupButton',
            'shapeToolGroupButton',
            'signatureToolButton',
            'eraserToolButton',
            'stickyToolButton',
            'freeTextToolButton',
            'miscToolGroupButton',
            'fileAttachmentToolButton',
            'rubberStampToolButton',
            'stampToolButton',
            'calloutToolButton',
            'header',
            'leftPanel',
            'searchPanel',
            'viewControlsOverlay',
            'menuOverlay',
            'annotationCommentButton',
            'annotationStyleEditButton',
            'annotationStylePopup',
            'toolStylePopup',
            'stylePopup',
            'textPopup',
            'contextMenuPopup',
            'calloutToolGroupButton',
            'annotationPopup',
          ],
        },
        viewer.current,
      ),
    ])
      .then(async ([axiosResponse, instance]) => {
        const { docViewer, annotManager, Annotations } = instance
        // load document
        const { pdfLink, xfdf, title, signedCount, participantCount } = axiosResponse.data
        setHeader(`${title} (${signedCount} of ${participantCount} completed)`)
        await docViewer.loadDocument(pdfLink)
        annotManager.on(
          'annotationChanged',
          async (annotations, action, { imported }) => {
            for (const annot of annotations) {
              annot.NoMove = true
              annot.NoResize = true
              if (annot instanceof Annotations.WidgetAnnotation) {
                if (imported && action === 'add') {
                  annot.Hidden = true
                  annot.Listable = false
                }
              }
            }
          },
        )
        annotManager.importAnnotations(xfdf)
      })
      .catch((error) => {
        console.error(error)
        if (error.response.data.message) {
          setAlertError(`Error Message: ${error.response.data.message}`)
        }
      })
      .finally(() => setIsLoading(false))
  }, [])

  return (
    <Spin spinning={isLoading} delay={100}>
      <div className="App">
        <div className="header">
          <img
            className="logo"
            alt="logo"
            src="/assets/eSign_Logo_negative.png"
          />
          <span className="doc-title">{header}</span>
        </div>
        {alertError && <div className="alert-bar">{alertError}</div>}
        <div className="webviewer" ref={viewer} />
      </div>
    </Spin>
  )
}

export default CopyDoc
