import { datadogRum } from '@datadog/browser-rum'

import DateTypes from '../enums/DateTypes'

const isArrayEmpty = (array) => !array || array.length === 0

const isObjectEmpty = (obj) => (
  obj === undefined || obj === null || (obj && Object.entries(obj).length <= 0)
)

const logError = (error = {}) => {
  if (error && error.response) {
    const { config: { url }, data, status } = error.response
    const { message } = data
    datadogRum.addError({ source: url, type: status, message, stack: data })
  }
}

const parseJSON = (str) => {
  let result
  try {
    result = JSON.parse(str)
  } catch (error) {
    result = {}
  }

  return result
}

const obfuscate = (key) => {
  const chunkSize = Math.ceil(key.length / 10)
  const chunks = []
  for (let i = 0; i < key.length; i += chunkSize) {
    chunks.push(key.substring(i, chunkSize + i))
  }
  chunks.reverse()
  return Buffer.from(chunks.join('')).toString('base64')
}

const deobfuscate = (obfuscatedKey) => {
  const obkey = Buffer.from(obfuscatedKey, 'base64').toString('utf-8')
  const chunkSize = Math.ceil(obkey.length / 10)
  const chunks = []
  const updatedKey = obkey.split('').reverse().join('')
  for (let i = 0; i < updatedKey.length; i += chunkSize) {
    chunks.push(updatedKey.substring(i, chunkSize + i))
  }
  chunks.reverse()
  const key = chunks.join('')
  return key.split('').reverse().join('')
}

const getAnnotationsToSave = (
  annotations = [],
  isSigned = false,
  annotationCreateToolNames = []
) => {
  if (isArrayEmpty(annotations)) return []
  return annotations.filter((annotation) => {
    const { Subject = '', ToolName = '' } = annotation
    const annotCustom = annotation.getCustomData('custom')
    let isSave = (ToolName === 'AnnotationCreateFreeText' && !isSigned)
      || (Subject === null && ToolName === 'AnnotationCreateRubberStamp')
      || Subject === 'Signature'
      || annotationCreateToolNames.includes(ToolName)
    if (annotCustom) {
      const { isBM = false, signerId = '', type = '' } = JSON.parse(annotCustom)
      isSave = (isSave && !!signerId) || !!DateTypes[type] || isBM
    }
    return isSave
  })
}

export {
  isArrayEmpty,
  isObjectEmpty,
  logError,
  parseJSON,
  obfuscate,
  deobfuscate,
  getAnnotationsToSave,
}
