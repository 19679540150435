// [NOTE]: Webviewer classes have their own set of Events
// So these are not universal (e.g annotationManager don't have documentLoaded)
module.exports = Object.freeze({
  ANNOTATION_ADDED: 'annotationAdded',
  ANNOTATION_CHANGED: 'annotationChanged',
  ANNOTATION_DESELECTED: 'annotationDeselected',
  ANNOTATION_SELECTED: 'annotationSelected',
  ANNOTATIONS_DRAWN: 'annotationsDrawn',
  ANNOTATIONS_LOADED: 'annotationsLoaded',
  DOCUMENT_LOADED: 'documentLoaded',
  DOCUMENT_UNLOADED: 'documentUnloaded',
  LOCATION_SELECTED: 'locationSelected',
  SIGNATURE_SAVED: 'signatureSaved',
})
