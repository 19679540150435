module.exports = Object.freeze({
  FREEHAND: 'FREEHAND',
  FREEHAND2: 'FREEHAND2',
  FREEHAND3: 'FREEHAND3',
  FREEHAND4: 'FREEHAND4',
  FREEHAND_HIGHLIGHT: 'FREEHAND_HIGHLIGHT',
  FREEHAND_HIGHLIGHT2: 'FREEHAND_HIGHLIGHT2',
  FREEHAND_HIGHLIGHT3: 'FREEHAND_HIGHLIGHT3',
  FREEHAND_HIGHLIGHT4: 'FREEHAND_HIGHLIGHT4',
  FREETEXT2: 'FREETEXT2',
  FREETEXT3: 'FREETEXT3',
  FREETEXT4: 'FREETEXT4',
  HIGHLIGHT: 'HIGHLIGHT',
  HIGHLIGHT2: 'HIGHLIGHT2',
  HIGHLIGHT3: 'HIGHLIGHT3',
  HIGHLIGHT4: 'HIGHLIGHT4',
  RECTANGLE: 'RECTANGLE',
  RECTANGLE2: 'RECTANGLE2',
  RECTANGLE3: 'RECTANGLE3',
  RUBBER_STAMP: 'RUBBER_STAMP',
  SQUIGGLY: 'SQUIGGLY',
  SQUIGGLY2: 'SQUIGGLY2',
  SQUIGGLY3: 'SQUIGGLY3',
  SQUIGGLY4: 'SQUIGGLY4',
  STRIKEOUT: 'STRIKEOUT',
  STRIKEOUT2: 'STRIKEOUT2',
  STRIKEOUT3: 'STRIKEOUT3',
  STRIKEOUT4: 'STRIKEOUT4',
  UNDERLINE: 'UNDERLINE',
  UNDERLINE2: 'UNDERLINE2',
  UNDERLINE3: 'UNDERLINE3',
  UNDERLINE4: 'UNDERLINE4',
})
