import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import './App.css'
import PrepareDoc from './PrepareDoc'
import SignDoc from './SignDoc'
import DocCopyView from './DocCopyView'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/prepare/:id"><PrepareDoc /></Route>
        <Route path="/sign/:token"><SignDoc /></Route>
        <Route path="//sign/:token">
          <RedirectToSignToken />
        </Route>
        <Route path="/copy/:token"><DocCopyView /></Route>
      </Switch>
    </Router>
  )
}

const RedirectToSignToken = () => {
  // Retrieve the token from the URL
  const outerToken = window.location.pathname.split('/').pop()

  if (outerToken) {
    // Redirect to the inner route with the token
    return <Redirect to={`/sign/${outerToken}`} />
  }

  // Handle the case when there's no token
  return <div>No token provided</div>
}

export default App
