import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'

const { REACT_APP_CONSOLE_LOGO, REACT_APP_CONSOLE_URL } = process.env

const Confirmation = (props) => {
  const { signUrl } = props

  const onClickUrl = (url) => {
    window.location.replace(url)
  }

  return (
    <div className="app-confirmation">
      <img
        className="logo"
        alt="logo"
        src={REACT_APP_CONSOLE_LOGO}
      />
      <h1>{signUrl ? 'You Still Have Documents To Sign!' : 'You\'re All Done!'}</h1>
      <p>We will let the sender know you have signed.</p>
      <Button
        type="primary"
        onClick={() => window.location.reload()}
      >
        Review signed document
      </Button>
      <div className="redirect-buttons">
        {signUrl
          && (
            <Button
              type="primary"
              onClick={() => onClickUrl(signUrl)}
            >
              Sign Next Document
            </Button>
          )
        }
        <Button
          type="primary"
          onClick={() => onClickUrl(REACT_APP_CONSOLE_URL)}
          className="link"
        >
          Go to Console
        </Button>
      </div>
    </div>
  )
}

Confirmation.defaultProps = {
  signUrl: '',
}

Confirmation.propTypes = {
  signUrl: PropTypes.string,
}

export default Confirmation
