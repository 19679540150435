/* eslint-disable max-len */
module.exports = Object.freeze({
  // Default Error Messages
  MSG_REQUEST_ENTITY_TOO_LARGE: 'request entity too large',

  // Error Messages
  MSG_SIZE_TOO_LARGE: 'The file size of this signature is too large, upload a smaller one or draw your signature.',
  MSG_AGREEMENT_MODIFIED: 'The agreement was signed while you were signing. Please retry.',

  // Error Titles
  TITLE_AGREEMENT_MODIFIED: 'The agreement has been modified.',
  TITLE_GENERIC: 'Something went wrong. Please try again shortly.',
})
